<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold pa-0"
        >Packages ({{ packageMeta.total }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-row v-show="showSearch" align="center" style="max-width: 300px;">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search project name or owner name"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <v-skeleton-loader
        type="actions"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <template v-else>
        <v-btn icon class="ml-5" @click="showSearchField">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="#383d3f"
              class="mr-1"
              icon
              @click="exportList"
              v-on="on"
            >
              <v-icon>{{ icons.export }}</v-icon>
            </v-btn>
          </template>
          <span>Export to Excel</span>
        </v-tooltip>
      </template>
    </v-app-bar>

    <div class="d-flex mt-6">
      <div>
        <!-- <v-btn
          color="primary"
          @click="
            $router.push({
              name: 'package.new'
            })
          "
        >
          <v-icon class="mr-1" small>{{ icons.add }}</v-icon
          >Add new package
        </v-btn> -->
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex">
        <v-select
          class="ml-10"
          hide-details
          flat
          solo
          dense
          item-text="label"
          item-value="value"
          v-model="defaultFilter"
          :items="filterBy"
          style="max-width: 200px;"
          @change="filterPackage"
        />

        <v-btn
          icon
          class="ml-3"
          :color="sortOrder ? 'primary' : ''"
          @click="
            sortOrder = true
            filterPackage()
          "
        >
          <v-icon>{{ icons.arrowUp }}</v-icon>
        </v-btn>

        <v-btn
          icon
          :color="!sortOrder ? 'primary' : ''"
          @click="
            sortOrder = false
            filterPackage()
          "
        >
          <v-icon>{{ icons.arrowDown }}</v-icon>
        </v-btn>
      </div>
    </div>

    <div
      class="mt-6"
      id="user-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th width="20%" class="text-left pa-0">Project Name</th>
            <th width="20%" class="text-left">Milestone</th>
            <th width="20%" class="text-left">Project Owner</th>
            <th width="10%" class="text-left">Total Resources</th>
            <th width="10%" class="text-left">Completed Resources</th>
            <th width="20%" class="text-left">Added date</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="projectPackage in packages"
            :key="projectPackage.id"
            class="clickable"
            @click="
              $router.push({
                name: 'packages.details',
                params: { id: projectPackage.id }
              })
            "
          >
            <td>
              <span v-if="projectPackage.project">
                {{ projectPackage.project.name }}
              </span>
            </td>
            <td>
              <span v-if="projectPackage.milestone">
                {{ projectPackage.milestone.name }}
              </span>
            </td>
            <td>
              {{
                projectPackage.project.owner.first_name +
                  ' ' +
                  projectPackage.project.owner.last_name
              }}
            </td>
            <td>
              {{ projectPackage.total_resources }}
            </td>
            <td>
              {{ projectPackage.total_completed_resources }}
            </td>
            <td>{{ projectPackage.addedDate }}</td>
          </tr>

          <template v-if="loading">
            <tr v-for="row in 10" :key="`${row}-skeleton`">
              <td class="pl-0" v-for="col in 6" :key="col">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <div
        class="d-flex justify-center mt-3"
        v-if="!(packages.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mapActions, mapMutations, mapState } from 'vuex'
import ControlsMixin from '@/utils/mixins/Controls'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
  mdiArrowUp,
  mdiArrowDown,
  mdiPlus
} from '@mdi/js'

export default {
  name: 'PackageIndexPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll
  },

  components: {
    AppBarNavIcon
  },

  data() {
    return {
      loading: false,
      showSearch: false,
      filter: {
        search: ''
      },
      defaultFilter: 'created_at',
      sortOrder: false,
      items: {},
      filterBy: [
        {
          value: 'project_name',
          label: 'Project Name'
        },
        {
          value: 'milestone_name',
          label: 'Milestone Name'
        },
        {
          value: 'owner',
          label: 'Owner'
        },
        {
          value: 'created_at',
          label: 'Added date'
        }
      ],
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus
      }
    }
  },

  computed: {
    ...mapState({
      packages: state => state.projectPackage.list,
      packageMeta: state => state.projectPackage.meta
    })
  },

  mounted() {
    this.clearPackages()
    this.getProjectPackages()
  },

  updated() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.searchField.$refs.input.focus()
      })
    })
  },

  methods: {
    ...mapActions({
      fetchPackages: 'projectPackage/fetchPackages',
      exportToExcel: 'projectPackage/exportPackages'
    }),

    ...mapMutations({
      clearPackages: 'projectPackage/clearPackageList'
    }),

    async getProjectPackages(page = 1) {
      if (this.loading) return

      // Filtering
      let params = {
        page,
        sort: this.sortOrder
          ? `${this.defaultFilter}`
          : `-${this.defaultFilter}`
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.fetchPackages(params)
      this.loading = false
    },

    loadMore() {
      if (this.packageMeta.current_page < this.packageMeta.last_page) {
        this.getProjectPackages(this.packageMeta.current_page + 1)
      }
    },

    filterPackage() {
      this.clearPackages()
      this.getProjectPackages()
    },

    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },

    search: debounce(function() {
      this.clearPackages()
      this.getProjectPackages()
    }, 600),

    async exportList() {
      this.loading = true

      await this.exportToExcel()
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            })
          )

          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'packages.xlsx')
          document.body.appendChild(link)
          link.click()

          this.$store.dispatch('showSnackbar', {
            message: 'File successfully downloaded',
            color: 'success'
          })
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', {
            message: 'Ops! Something went wrong',
            color: 'red'
          })
        })

      this.loading = false
    }
  }
}
</script>
