var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"main-appbar",attrs:{"flat":"","color":"transparent"}},[_c('app-bar-nav-icon'),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"chip","transition":"fade-transition"}}):_c('v-toolbar-title',{staticClass:"headline font-weight-bold pa-0"},[_vm._v("Packages ("+_vm._s(_vm.packageMeta.total)+")")]),_c('v-spacer'),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSearch),expression:"showSearch"}],staticStyle:{"max-width":"300px"},attrs:{"align":"center"}},[_c('v-text-field',{ref:"searchField",staticClass:"search",attrs:{"flat":"","hide-details":"","solo":"","rounded":"","clearable":"","background-color":"grey lighten-3","label":"Search project name or owner name"},on:{"input":_vm.search},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"actions","transition":"fade-transition"}}):[_c('v-btn',{staticClass:"ml-5",attrs:{"icon":""},on:{"click":_vm.showSearchField}},[_c('img',{attrs:{"src":require("@/assets/icons/search.svg"),"alt":"","height":"17.7px"}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"color":"#383d3f","icon":""},on:{"click":_vm.exportList}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.export))])],1)]}}])},[_c('span',[_vm._v("Export to Excel")])])]],2),_c('div',{staticClass:"d-flex mt-6"},[_c('div'),_c('v-spacer'),_c('div',{staticClass:"d-flex"},[_c('v-select',{staticClass:"ml-10",staticStyle:{"max-width":"200px"},attrs:{"hide-details":"","flat":"","solo":"","dense":"","item-text":"label","item-value":"value","items":_vm.filterBy},on:{"change":_vm.filterPackage},model:{value:(_vm.defaultFilter),callback:function ($$v) {_vm.defaultFilter=$$v},expression:"defaultFilter"}}),_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","color":_vm.sortOrder ? 'primary' : ''},on:{"click":function($event){_vm.sortOrder = true
          _vm.filterPackage()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.arrowUp))])],1),_c('v-btn',{attrs:{"icon":"","color":!_vm.sortOrder ? 'primary' : ''},on:{"click":function($event){_vm.sortOrder = false
          _vm.filterPackage()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.arrowDown))])],1)],1)],1),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],staticClass:"mt-6",attrs:{"id":"user-list","infinite-scroll-disabled":_vm.loading,"infinite-scroll-distance":"100"}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left pa-0",attrs:{"width":"20%"}},[_vm._v("Project Name")]),_c('th',{staticClass:"text-left",attrs:{"width":"20%"}},[_vm._v("Milestone")]),_c('th',{staticClass:"text-left",attrs:{"width":"20%"}},[_vm._v("Project Owner")]),_c('th',{staticClass:"text-left",attrs:{"width":"10%"}},[_vm._v("Total Resources")]),_c('th',{staticClass:"text-left",attrs:{"width":"10%"}},[_vm._v("Completed Resources")]),_c('th',{staticClass:"text-left",attrs:{"width":"20%"}},[_vm._v("Added date")])])]),_c('tbody',[_vm._l((_vm.packages),function(projectPackage){return _c('tr',{key:projectPackage.id,staticClass:"clickable",on:{"click":function($event){return _vm.$router.push({
              name: 'packages.details',
              params: { id: projectPackage.id }
            })}}},[_c('td',[(projectPackage.project)?_c('span',[_vm._v(" "+_vm._s(projectPackage.project.name)+" ")]):_vm._e()]),_c('td',[(projectPackage.milestone)?_c('span',[_vm._v(" "+_vm._s(projectPackage.milestone.name)+" ")]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(projectPackage.project.owner.first_name + ' ' + projectPackage.project.owner.last_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(projectPackage.total_resources)+" ")]),_c('td',[_vm._v(" "+_vm._s(projectPackage.total_completed_resources)+" ")]),_c('td',[_vm._v(_vm._s(projectPackage.addedDate))])])}),(_vm.loading)?_vm._l((10),function(row){return _c('tr',{key:(row + "-skeleton")},_vm._l((6),function(col){return _c('td',{key:col,staticClass:"pl-0"},[_c('v-skeleton-loader',{attrs:{"type":"list-item","transition":"fade-transition"}})],1)}),0)}):_vm._e()],2)]),(!(_vm.packages.length > 0) && !_vm.loading)?_c('div',{staticClass:"d-flex justify-center mt-3"},[_c('p',[_vm._v("No match found.")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }